import React from "react"
import Recipes from "../templates/Recipes"
import { graphql } from "gatsby"
import PostListPage from "../components/commons/post-list-page"

const Receitas = ({ data }) => {
  const options = {
    title: "Receitas",
    titleColor: "#143d6e",
    titleBg: "#d7c0cf",
  }

  return (
    <PostListPage options={options}>
      <Recipes categories={data} />
    </PostListPage>
  )
}

export default Receitas

export const query = graphql`
  query Recipes {
    allWpCategory {
      nodes {
        name
        id
        databaseId
        slug
      }
    }
  }
`
