import React from "react"
import Select, { components } from "react-select"
import styled from "@emotion/styled"

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      nenhuma opção
    </components.NoOptionsMessage>
  )
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        className="indicator"
        xmlns="http://www.w3.org/2000/svg"
        width="16.828"
        height="16"
        viewBox="0 0 16.828 16"
      >
        <g id="arrow-right" transform="translate(20.414 -4) rotate(90)">
          <line
            x2="14"
            transform="translate(5 12)"
            fill="none"
            stroke="#acaca3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            d="M12,5l7,7-7,7"
            fill="none"
            stroke="#acaca3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    </components.DropdownIndicator>
  )
}

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    fontSize: "1.125rem",
    fontWeight: "500",
    color: "#ACACA3",
    padding: "7px 10px",
    backgroundColor: "white",
    borderColor: state.isFocused ? "#EBEBEB" : "#D0D0C6",
    outline: "0",
    boxShadow: state.isFocused ? "0 0 0 2px #EBEBEB" : "none",
    ":hover": {
      borderColor: state.isFocused ? "#EBEBEB" : "#D0D0C6",
    },

    "& .indicator": {
      transform: state.menuIsOpen ? "rotate(180deg)" : "none",
    },
  }),

  placeholder: () => ({ color: "#ACACA3" }),
  input: () => ({ color: "#ACACA3" }),

  menuList: (styles, state) => ({
    ...styles,
    padding: "7px 0",
    borderColor: state.isSelected ? "#D0D0C6" : "#D0D0C6",
  }),

  menu: (styles) => ({ ...styles, marginTop: "10px" }),

  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected 
      ? "#ACACA3" 
      : state.isFocused 
      ? "#F5F5F5"
      : null,
    fontSize: "1.125rem",
    fontWeight: "500",
    color: state.isSelected ? "white" : "#ACACA3",
    padding: "10px 19px",
    ":active, :hover, :focus": {
      backgroundColor: state.isSelected ? "#ACACA3" : "#F5F5F5",
      color: state.isSelected ? "white" : "#ACACA3",
    },
  }),

  singleValue: () => ({ color: "#ACACA3" }),
  indicatorSeparator: () => ({}),

  noOptionsMessage: (styles) => ({
    ...styles,
    fontSize: "1.125rem",
    textAlign: "left",
    color: "#ACACA3",
  }),
}

const Filter = ({ getSelected, label, options }) => {
  const sendData = ({ value }) => {
    getSelected(value)
  }

  return (
    <FilterWrapper>
      <Label>{label}</Label>
      <Select
        defaultValue={options[0]}
        options={options}
        styles={customStyles}
        components={{ DropdownIndicator, NoOptionsMessage }}
        onChange={sendData}
      />
    </FilterWrapper>
  )
}

const Label = styled.span`
  display: block;
  font-size: 1.25rem;
  color: #143d6e;
  margin-bottom: 10px;
  text-transform: lowercase;
`

const FilterWrapper = styled.div`
  padding: 10px 0;
`

export default Filter
