import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import { CardsContainer } from "../../components/commons/cards-container"
import Container from "../../components/commons/container"
import Filter from "../../components/commons/filter-select"
import { gql, useQuery } from "@apollo/client"
import { roundButtonStyles } from "../../components/commons/round-button-styles"
import RecipeCard from "../../components/commons/recipe-card"

const RECIPES = gql`
  query Recipes(
    $category: [ID]
    $difficulty: Int
    $order: OrderEnum = DESC
    $cookingTime: Int
    $first: Int!
    $after: String
  ) {
    posts(
      first: $first
      after: $after
      where: {
        categoryIn: $category
        difficulty: $difficulty
        orderby: { field: DATE, order: $order }
        filterTime: $cookingTime
      }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      edges {
        node {
          title
          slug
          featuredImage {
            node {
              altText
              srcSet
            }
          }
          acfRecipe {
            lead
            difficulty
            cookingtime {
              hours
              minutes
            }
            maintag {
              name
            }
          }
        }
      }
    }
  }
`

const BATCH_SIZE = 24

const Recipes = ({ categories }) => {
  const { allWpCategory } = categories
  const allCategories = allWpCategory.nodes.map(({ databaseId }) => databaseId)

  let categoriesList = allWpCategory.nodes.map((category) => {
    return {
      value: category.databaseId,
      label: category.name,
    }
  })

  categoriesList = [{ value: "", label: "todas" }, ...categoriesList]

  const difficultiesList = [
    { value: 0, label: "todos" },
    { value: 10, label: "fácil" },
    { value: 20, label: "intermediário" },
    { value: 30, label: "desafiador" },
  ]

  const orderList = [
    { value: "DESC", label: "mais novos" },
    { value: "ASC", label: "mais antigos" },
  ]

  const cookingTimeList = [
    { value: 0, label: "todos" },
    { value: 10, label: "até 30 minutos" },
    { value: 20, label: "até 1 hora" },
    { value: 30, label: "mais de 1 hora" },
  ]
  const [orderFilter, setOrderFilter] = useState("DESC")
  const [categoryFilter, setCategoryFilter] = useState(allCategories)
  const [difficultyFilter, setDifficultyFilter] = useState(0)
  const [cookingTimeFilter, setCookingTimeFilter] = useState(0)

  const handleSelectedCategory = (selectedOption) =>
    selectedOption === ""
      ? setCategoryFilter(allCategories)
      : setCategoryFilter(selectedOption)

  const handleSelectedDifficulty = (selectedOption) => {
    setDifficultyFilter(selectedOption)
  }

  const handleSelectedOrder = (selectedOption) => {
    setOrderFilter(selectedOption)
  }

  const handleSelectedTime = (selectedOption) => {
    setCookingTimeFilter(selectedOption)
  }

  const { loading, error, data, fetchMore, refetch } = useQuery(RECIPES, {
    variables: {
      first: BATCH_SIZE,
      after: null,
      category: categoryFilter,
      difficulty: difficultyFilter,
      order: orderFilter,
      cookingTime: cookingTimeFilter,
    },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    refetch({
      category: categoryFilter,
      difficulty: difficultyFilter,
      order: orderFilter,
      cookingTime: cookingTimeFilter,
    })
  }, [categoryFilter, difficultyFilter, orderFilter, cookingTimeFilter])
  
  const haveMorePosts = Boolean(data?.posts?.pageInfo?.hasNextPage)

  const GetRecipesResults = () => {
    if (error) {
      return <BottomMessage>Desculpe, ocorreu um erro. Por favor, recarregue a página</BottomMessage>
    }

    if (!data && loading) {
      return <BottomMessage>carregando receitas...</BottomMessage>
    }

    if (!data?.posts?.edges.length) {
      return <BottomMessage>nenhuma receita encontrada</BottomMessage>
    }

    return (
      <>
        <CardsContainer
          css={css`
            row-gap: 3rem;
          `}
        >
          {data?.posts?.edges.map((post, i) => (
            <RecipeCard key={i} post={post.node} />
          ))}
        </CardsContainer>
        {haveMorePosts && data ? (
          <form
            method="post"
            onSubmit={(event) => {
              event.preventDefault()
              fetchMore({ variables: { after: data.posts.pageInfo.endCursor } })
            }}
          >
            <RoundedButton
              colors={{
                color: "#143d6e",
                bkg: "transparent",
                hoverColor: "white",
              }}
              type="submit"
              disabled={loading}
            >
              {loading ? "carregando..." : "carregar mais"}
            </RoundedButton>
          </form>
        ) : (
          <BottomMessage>não há mais receitas</BottomMessage>
        )}
      </>
    )
  }


  return (
    <Container
      css={css`
        padding: 48px 25px;

        @media (min-width: 1250px) {
          margin: 0 auto;
          padding: 48px 0 40px;
        }
      `}
    >
      <FiltersContainer>
        <Filter
          label="Ordernar por"
          getSelected={handleSelectedOrder}
          options={orderList}
        />
        <Filter
          label="Categorias"
          getSelected={handleSelectedCategory}
          options={categoriesList}
        />
        <Filter
          label="Dificuldade"
          getSelected={handleSelectedDifficulty}
          options={difficultiesList}
        />
        <Filter
          label="Tempo de preparo"
          getSelected={handleSelectedTime}
          options={cookingTimeList}
        />
      </FiltersContainer>
      <GetRecipesResults />
    </Container>
  )
}

export default Recipes

const FiltersContainer = styled.div`
  margin-bottom: 40px;
  position: relative;
  z-index: 10;

  @media (min-width: 800px) {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    grid-gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
  }
`

const RoundedButton = styled.button`
  ${roundButtonStyles}
  margin-top: 80px;
`

const BottomMessage = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 80px;
`
