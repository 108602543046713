import React from "react"
import styled from "@emotion/styled"
import Layout from "../layout"
import { Article } from "./styled-components"

const PostList = ({ options, children }) => {
  const { title, titleColor, titleBg } = options

  return (
    <Layout>
      <Article>
        <PageTitleContainer bgColor={titleBg}>
          <PageTitle titleColor={titleColor}>{title}</PageTitle>
        </PageTitleContainer>
        {children}
      </Article>
    </Layout>
  )
}

export default PostList

const PageTitleContainer = styled.header`
  background-color: ${(props) => props.bgColor};
  margin-top: -45px;
  padding: 115px 0 75px;
  position: relative;
  z-index: 99;

  @media (min-width: 800px) {
    padding: 60px 0 0;
  }
`

const PageTitle = styled.h1`
  color: ${(props) => props.titleColor};
  font-size: 5.625rem;
  font-weight: 300;
  margin: 0;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: 800px) {
    font-size: 15rem;
  }
  
  @media (min-width: 1250px) {
    font-size: 25rem;
  }
`